import React, { Children } from 'react';
import styled from '@emotion/styled';
import SVG from 'react-inlinesvg';

import { useFullLogo } from '../hooks/logos';

import { useIcons } from '../hooks/icons';
import GlobalStyles from './../components/global-styles';

const InstagramFrame: React.FC<{
  title: string;
  underline: React.ReactNode;
}> = ({ children, title, underline }) => {
  const logo = useFullLogo();
  const icons = useIcons();

  return (
    <Container>
      <GlobalStyles />
      <ResizeWarpper>
        <SquareContainer id="snapshot-container">
          <SVG src={logo.publicURL} className="logo" width="834" height="96" />
          <div className="title">{title}</div>
          <div className="overline">This Contract:</div>
          {children}
          <div className="underline">{underline}</div>
          <SVG src={icons.instagramFooter.publicURL} className="footer" />
        </SquareContainer>
      </ResizeWarpper>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #666;

  width: 100%;
  height: 100%;
  position: absolute;
`;

const squareSize: number = 1080;

export const ResizeWarpper = styled.div`
  transform: scale(0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #666;
`;

export const SquareContainer = styled.div`
  @import url('https://p.typekit.net/p.css?s=1&k=als5mhq&ht=tk&f=26070.26072.26073.26074.26075.26078.26080.26082.26086.26087&a=41636528&app=typekit&e=css');

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/afebc7/00000000000000003b9acb47/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/afebc7/00000000000000003b9acb47/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
        format('woff'),
      url('https://use.typekit.net/af/afebc7/00000000000000003b9acb47/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 800;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/be1794/00000000000000003b9acb45/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/72fcf6/00000000000000003b9acb46/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/72fcf6/00000000000000003b9acb46/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/72fcf6/00000000000000003b9acb46/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/9c8f5a/00000000000000003b9acb3b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/9c8f5a/00000000000000003b9acb3b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
        format('woff'),
      url('https://use.typekit.net/af/9c8f5a/00000000000000003b9acb3b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 200;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/1b8691/00000000000000003b9acb3d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/1b8691/00000000000000003b9acb3d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('woff'),
      url('https://use.typekit.net/af/1b8691/00000000000000003b9acb3d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/cc64d9/00000000000000003b9acb41/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/cc64d9/00000000000000003b9acb41/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
        format('woff'),
      url('https://use.typekit.net/af/cc64d9/00000000000000003b9acb41/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/2acd47/00000000000000003b9acb43/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/2acd47/00000000000000003b9acb43/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('woff'),
      url('https://use.typekit.net/af/2acd47/00000000000000003b9acb43/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/601890/00000000000000003b9acb39/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/601890/00000000000000003b9acb39/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
        format('woff'),
      url('https://use.typekit.net/af/601890/00000000000000003b9acb39/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/e3a744/00000000000000003b9acb3f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
        format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'acumin-pro-wide';
    src: url('https://use.typekit.net/af/a77ed7/00000000000000003b9acb40/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/a77ed7/00000000000000003b9acb40/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/a77ed7/00000000000000003b9acb40/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
        format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
  }

  .tk-acumin-pro-wide {
    font-family: 'acumin-pro-wide', sans-serif;
  }

  background: #231f20;

  width: ${squareSize}px;
  height: ${squareSize}px;

  min-width: ${squareSize}px;
  min-height: ${squareSize}px;

  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;

  position: relative;

  .logo {
    margin-top: 64px;
  }

  .title {
    margin-top: 8px;
    font-family: acumin-pro-wide, sans-serif;
    font-weight: 800;
    font-size: 80px;
    color: #ff921e;
    letter-spacing: 0;
    text-align: center;
    line-height: 104px;
  }

  .overline {
    margin-top: 8px;
    font-family: acumin-pro-wide, sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 37px;
    text-transform: uppercase;
  }

  .underline {
    margin-top: 40px;
    font-family: acumin-pro-wide, sans-serif;
    font-weight: 400;
    font-size: 29px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 37px;

    > strong {
      font-weight: 800;
    }

    > em {
      color: #ff8c00;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
  }

  .dimmed {
    opacity: 0.2;
  }
`;

export default InstagramFrame;
