import React, { useRef, useLayoutEffect, useState, Fragment } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SVG from 'react-inlinesvg';
import htmlToImage from 'html-to-image';
import domToImage from 'dom-to-image';
import { saveAs } from 'file-saver';

import { useFullLogo } from '../hooks/logos';

import { useIcons } from '../hooks/icons';
import InstagramFrame from './../components/instagram-frame';
import SEO from '../components/seo';
import GlobalStyles from './../components/global-styles';
import { Details, Summary } from 'react-accessible-details';
import {
  mapLocation,
  AirtableIssueType,
  AirtableLocation,
  mapIssueType,
} from '../airtable-mapping';
import { IssueType } from '../interfaces';

export const shareImage = (
  imageURL: string,
  imageFile: File,
  fileName: string
) => {
  let newVariable: any;

  newVariable = window.navigator;
  console.log(imageFile);

  if (
    newVariable &&
    newVariable.share &&
    false
    // &&
    // newVariable.canShare &&
    // newVariable.canShare({ files: [imageFile] })
  ) {
    const pageUrl = typeof window !== 'undefined' ? window.location.href : '';
    newVariable
      .share({
        title: 'title',
        text: 'description',
        // url: pageUrl,
        files: [imageFile],
      })
      .then(() => console.log('Successful share'))
      .catch(error => {
        console.log('Error sharing', error);
        alert(error);
      });
  } else {
    saveAs(imageURL, fileName);
  }
};

//return a promise that resolves with a File instance
export function urlToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, {
        type: mimeType,
      });
    });
}

const InstagramCity: React.FC<{
  pageContext: {
    airtableLocation: AirtableLocation;
    airtableIssueTypes: AirtableIssueType[];
  };
}> = ({ pageContext }) => {
  const city = pageContext.airtableLocation
    ? mapLocation(pageContext.airtableLocation)
    : undefined;

  const state = city?.containedBy[0];

  const allIssueTypes =
    pageContext?.airtableIssueTypes?.map(value => value.name.toString()) ?? [];

  const currentIssueTypes =
    city?.contracts[0]?.issues.map(value => value.type.name.toString()) ?? [];

  const ref = useRef<HTMLDivElement>(null);

  const [imageURL, setImageURL] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();

  // const

  useLayoutEffect(() => {
    if (ref && ref.current && document.getElementById('snapshot-container')) {
      setTimeout(() => {
        domToImage
          .toPng(document.getElementById('snapshot-container')!)
          .then(function (dataUrl) {
            urlToFile(
              imageURL!,
              `NixThe6-${city?.name}${
                state?.shortName ? '-' + state.shortName : ''
              }.png`,
              'image/png'
            ).then(function (file) {
              console.log(file);
              setImageURL(dataUrl);
              setImageFile(file);
              // shareImage(imageURL!, file);
            });
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
      }, 350);
    }
  }, [ref]);

  if (imageFile) {
    shareImage(
      imageURL!,
      imageFile!,
      `NixThe6-${city?.name}${
        state?.shortName ? '-' + state.shortName : ''
      }.png`
    );
  }

  return (
    <div ref={ref}>
      {/* <button
        value="Lorem"
        onClick={() => {
          // shareImage(imageURL!);
          shareImage(imageURL!, imageFile!);
        }}
      >
        XXX
      </button> */}
      <InstagramFrame
        title={`${city?.name}
                ${state?.shortName ? ', ' + state.shortName : ''}`}
        underline={
          <>
            Find your <strong>city's police union contract</strong>,<br />
            call your mayor, and <em>demand change</em>.
          </>
        }
      >
        <Container>
          <ol>
            {Array.from({ length: 6 }).map((_, i) => (
              <Fragment key={`f-` + i}>
                <div className="line"></div>
                <li
                  className={
                    currentIssueTypes.includes(allIssueTypes[i]) ? '' : 'dimmed'
                  }
                >
                  <span className="number">{i + 1}</span>
                  {allIssueTypes[i]}
                </li>
              </Fragment>
            ))}
            <div className="line"></div>
          </ol>
        </Container>
      </InstagramFrame>
    </div>
  );
};
export const Container = styled.div`
  width: 940px;

  ol {
    list-style: none;
    counter-reset: i;
    padding: 0;
    margin: 0;
    margin-top: 16px;
  }
  ol li {
    height: 80px;
    display: flex;
    position: relative;
    font-family: acumin-pro-wide;
    font-size: 22px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 26px;
    align-items: center;
  }

  ol li .number {
    text-transform: uppercase;

    font-size: 44px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-family: VTCMarsha, acumin-pro-wide, sans-serif;
    font-weight: 700;
    padding-top: 4px;
    width: 80px;
    flex-grow: 0; /* do not grow   - initial value: 0 */
    flex-shrink: 0; /* do not shrink - initial value: 1 */
    flex-basis: 80px; /* width/height  - initial value: auto */
    margin: 0 72px 0 44px;
  }

  ol li::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    left: 44px;
    top: 38px;
    background-color: #ff921e;
    position: absolute;
  }

  .line {
    /* position: absolute; */
    /* width: 840px; */
    height: 2px;
    background: rgba(216, 216, 216, 0.1);
    top: 150px;
  }
`;

export default InstagramCity;
